import {createSlice} from "@reduxjs/toolkit";

import {lookupAddressByPostcode} from "http/addresses";
import {startLoading, loadingFailed} from "redux/helpers/actions";
import {extractError} from "redux/helpers";
import {actions as authActions} from "redux/auth";

import initialState, * as handlers from "./handlers";

const {actions, reducer} = createSlice({
  reducers: {
    getAddressByPostCodeRequest: startLoading,
    getAddressByPostCodeSuccess: handlers.getAddressByPostCodeSuccess,
    getAddressByPostCodeFailure: loadingFailed,
  },
  extraReducers: {
    [authActions.logout]: handlers.clearState,
  },
  initialState,
  name: "addresses",
});

export const getAddressByPostCode = postcode => async dispatch => {
  try {
    dispatch(actions.getAddressByPostCodeRequest());

    const data = await lookupAddressByPostcode(postcode, "GB");

    dispatch(actions.getAddressByPostCodeSuccess(data));
  } catch (error) {
    dispatch(actions.getAddressByPostCodeFailure(extractError(error)));
  }
};

export default reducer;
