import addresses from "./addresses";
import app from "./app";
import auth from "./auth";
import branches from "./branches";
import company from "./company";
import locales from "./locales";
import users from "./users";
import notificationSettings from "./notificationSettings";
import recovery from "./recovery";
import registration from "./registration";
import rentPassportList from "./rentPassportList";
import rentPassportRequest from "./rentPassportRequest";
import rightToRentList from "./rightToRentList";
import jointScreening from "./jointScreening";
import fileUpload from "./fileUpload";

const rootReducer = {
  addresses,
  app,
  auth,
  ...branches,
  ...jointScreening,
  company,
  locales,
  recovery,
  registration,
  rentPassportList,
  rentPassportRequest,
  rightToRentList,
  fileUpload,
  notificationSettings,
  ...users,
};

export default rootReducer;
